/* Nunito-300 Light - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url("../cdn/webfonts/Nunito-Light.eot");
         src: local("☺"), url("../cdn/webfonts/Nunito-Light.woff") format("woff"), url("../cdn/webfonts/Nunito-Light.ttf") format("truetype"), url("../cdn/webfonts/Nunito-Light.svg") format("svg"); font-display: swap;}
       
  /* Nunito-400 Regular - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url("../cdn/webfonts/Nunito-Regular.eot");
         src: local("☺"), url("../cdn/webfonts/Nunito-Regular.woff") format("woff"), url("../cdn/webfonts/Nunito-Regular.ttf") format("truetype"), url("../cdn/webfonts/Nunito-Regular.svg") format("svg"); font-display: swap;}

  /* Nunito-600 SemiBold- latin */
  @font-face {
    font-family: 'Nunitos';
    font-style: normal;
    font-weight: 600;
    src: url("../cdn/webfonts/Nunito-SemiBold.eot");
         src: local("☺"), url("../cdn/webfonts/Nunito-SemiBold.woff") format("woff"), url("../cdn/webfonts/Nunito-SemiBold.ttf") format("truetype"), url("../cdn/webfonts/Nunito-SemiBold.svg") format("svg"); font-display: swap;}

  /* Nunito-700 Bold - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url("../cdn/webfonts/Nunito-Bold.eot");
         src: local("☺"), url("../cdn/webfonts/Nunito-Bold.woff") format("woff"), url("../cdn/webfonts/Nunito-Bold.ttf") format("truetype"), url("../cdn/webfonts/Nunito-Bold.svg") format("svg"); font-display: swap;}



/* Material Icons Regular */
         @font-face {
          font-family: 'Material Icons';
          font-style: normal;
          font-weight: 400;
          src: url("../cdn/webfonts/MaterialIcons-Regular.eot");
            src: local("☺"), url("../cdn/webfonts/MaterialIcons-Regular.woff") format("woff"), url("../cdn/webfonts/MaterialIcons-Regular.ttf") format("truetype"), url("../cdn/webfonts/MaterialIcons-Regular.svg") format("svg"); font-display: swap;}


          .material-icons {
               font-family: 'Material Icons';
               font-weight: normal;
               font-style: normal;
              /*  font-size: 24px;  Preferred icon size */
               display: inline-block;
               line-height: 1;
               text-transform: none;
               letter-spacing: normal;
               word-wrap: normal;
               white-space: nowrap;
               direction: ltr;
             
               /* Support for all WebKit browsers. */
               -webkit-font-smoothing: antialiased;
               /* Support for Safari and Chrome. */
               text-rendering: optimizeLegibility;
             
               /* Support for Firefox. */
               -moz-osx-font-smoothing: grayscale;
             
               /* Support for IE. */
               font-feature-settings: 'liga';
          }

            /* Material Icons outline */
         @font-face {
          font-family: 'Material Icons Outline';
          font-style: normal;
          font-weight: 400;
          src: url("../cdn/webfonts/MaterialIconsOutlined-Regular.eot");
            src: local("☺"), url("../cdn/webfonts/MaterialIconsOutlined-Regular.woff") format("woff"), url("../cdn/webfonts/MaterialIconsOutlined-Regular.ttf") format("truetype"), url("../cdn/webfonts/MaterialIconsOutlined-Regular.svg") format("svg"); font-display: swap;}
        



          .material-icons-outline {
               font-family: 'Material Icons Outline';
               font-weight: normal;
               font-style: normal;
               /*font-size: 24px;   Preferred icon size */
               display: inline-block;
               line-height: 1;
               text-transform: none;
               letter-spacing: normal;
               word-wrap: normal;
               white-space: nowrap;
               direction: ltr;
             
               /* Support for all WebKit browsers. */
               -webkit-font-smoothing: antialiased;
               /* Support for Safari and Chrome. */
               text-rendering: optimizeLegibility;
             
               /* Support for Firefox. */
               -moz-osx-font-smoothing: grayscale;
             
               /* Support for IE. */
               font-feature-settings: 'liga';
        }